<div class="swiper-slide" (click)="onGameClick()">
  <div class="play-effect">
    <img src="assets/desktop/play-button-overlay.svg" alt="play-button-overlay">
  </div>
  <div class="thumbnail" [ngClass]="{ 'bonus_exclude_overlay': isBonusExcludedGame }" >
    <img alt="{{game.name}}" data-src="{{ game.thumbnail?.url || game?.thumbnailDesktopSquare?.url }}" />
    <!-- <div *ngIf="game.tag?.includes('exclusive')" class="exclusive">
      <img src="assets/icons/tag-lemon-exclusive-mobile.svg">
    </div> -->
    <div *ngIf="game?.tag?.includes('spin_gifts')" class="spin_gifts">
      <img src="assets/icons/spin-gifts-desktop-60x40.png">
    </div>
    <div *ngIf="game?.tag?.includes('reel_fortune')" class="reel_fortune">
      <img src="assets/icons/ROF-desktop-52x52.png">
    </div>
    <!-- <div *ngIf="isBonusExcludedGame" class="bonus_exclude">
      <img  src="{{licenceService.getKey('bonus-excluded')}}" alt="bonusExclude"/>
    </div> -->
    <div class="icon-tags-container">
      <div *ngIf="isBonusExcludedGame||favouriteGame"class="icon-tags">
        <img  *ngIf="isBonusExcludedGame" src="{{licenceService.getKey('icon-tag-bonus-excluded')}}" alt="bonusExclude"/>
        <img  *ngIf="favouriteGame" src="{{licenceService.getKey('icon-fav')}}" alt="favouriteGame"/>
      </div>
      <div *ngIf="game.tag?.includes('exclusive')" class="exclusive">
        <img src="assets/icons/tag-lemon-exclusive-mobile.svg">
      </div>
    </div>
  </div>
  <p style="padding-top: 0.25rem;">{{game.name}}</p>
</div>
