<div class="bonus_dialog-wrapper">
    <!-- <div class="bonus_dialog-header">
        <app-close-dialog-button [customClass]="'medium'" (closeEvent)="closeDialog()"></app-close-dialog-button>
    </div> -->

    <div class="dialog-body">
        <div class="bonus_exclude_img"><img src="{{licenceService.getKey('bonus-excluded')}}" alt="bonusExclude"/></div>
        <p i18n>Bonus excluded game.</p>
        <p class="bonus_exclude_text" i18n>You are using your Real Money balance.</p>
    </div>

    <div class="dialog-footer">
        <div class="buttons">
            <button round-button color="primary" (click)="closeDialog()" type='button' i18n>ok</button>
        </div>
    </div>
</div>