import { GamificationLevelService } from 'src/app/services/profile/gamification-level.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { GameStateService } from './services/profile/game-state.service';
import { Component, ElementRef, HostListener, Inject, LOCALE_ID, PLATFORM_ID, Renderer2, ViewChild, effect } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BasePageComponentWithDialogs } from './components/base-page/base-page.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationStart, Router, RouterEvent } from '@angular/router';
import { handleMessages } from './utils/bo-messages.handler';
import { delay, distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { topMenuAction } from './components/top-menu/top-menu-button/top-menu-button.component';
import { LoginStatusService } from './services/auth/login/login-status.service';
import { LoggedStatus } from './services/auth/login/login.models';
import { PreloadService } from './services/utils/preload-on-start.service';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { DetectDeviceService } from './services/utils/detect-device.service';
import { MinimizeSiteService } from './services/utils/hide-footer.service';
import { OpenDesktopDrawerService } from './services/utils/open-desktop-drawer.service';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NotificationService } from './services/notofications/notification.service';
import { InitialDataService } from './services/page/initial-data.service';
import { desktopTrx, loginTrx, signUpTrx } from './router-translation.labels';
import { LicenceService } from './services/utils/licence.service';
import { NavigationService } from './services/navigation/navigation.service';
import { environment } from 'src/environments/environment';
import { COUNTRY_ID, HOST_NAME, LICENCE } from './global.tokens';
import { UserCampaignService } from './services/campaigns/campaign.service';
import { UserInfoService } from './services/auth/check-session/user-info.service';
import { DynamicNotificationComponent } from './components/notifications/dynamic-notification/dynamic-notification.component';
import { BonusQueueService } from './services/wallet/bonus-queue.service';
import { DepositService } from './services/payment/deposit/deposit.service';
import { hashCode } from './utils/general-utils';
import { SeoSchemaValidator } from './services/seo/seo-schema-validator';

declare let gtag: Function;



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BasePageComponentWithDialogs {
  title = 'casino-first';
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('mainbackground') mainBackground: ElementRef;

  TOP_MENU_IDENTIFIER: string = 'top_menu';

  loginDialogLink: string;
  isOpened: boolean = false;
  minimizePage: boolean = false;
  isMaintenance: boolean = false;

  isPromotion: boolean = false;
  showInstall: boolean = false;
  installEvent: any;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  isRegularPage: boolean;
  isFullPage: boolean;
  // isToolBar: boolean;
  // isFooter: boolean;
  displayGamificationProgress: boolean = false;
  level: number
  constructor(
    public navigation: NavigationService,
    dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private loginStatus: LoginStatusService,
    private preloadService: PreloadService,
    public detectDeviceService: DetectDeviceService,
    public matDialog: MatDialog,
    public minimizeSiteService: MinimizeSiteService,
    public openDrawerService: OpenDesktopDrawerService,
    private notificationService: NotificationService,
    private intialDataService: InitialDataService,
    private snackBar: MatSnackBar,
    private cookiesService: CookieService,
    private userCampaignService: UserCampaignService,
    protected licenceService: LicenceService,
    protected translationConfig: TranslationConfig,
    private gameStateService: GameStateService,
    private gamificationLevelService: GamificationLevelService,
    private campaignService: UserCampaignService,
    private userInfoLoginService: UserInfoService,
    private depositService: DepositService,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(LOCALE_ID) protected locale,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(LICENCE) private licence: String,
    @Inject(HOST_NAME) private hostname: String,
    private _renderer2: Renderer2,
    private bonusQueueService: BonusQueueService,
    private seoSchemaValidator: SeoSchemaValidator
  ) {
    effect(() => {
      this.displayGamificationProgress = gamificationLevelService.animateGamificationProgress();
    });
    super(dialog, detectDeviceService);
    if (!isPlatformServer(this.platformId)) {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        this.installEvent = e;
        if (environment.enableServiceWorker)
          this.showInstallPopup();
        console.log('fired beforeinstallprompt')
      })

      window.addEventListener('appinstalled', (e) => {
        console.log('Installation completed')
      })
    }

  }


  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    location.reload()
  }

  ngOnInit(): void {
    this.setSchemaSets() // function will added the schema to head with help of schema service
    this.isRegularPage = true;
    this.isFullPage = true;

    this.gamificationLevelService.getGamification().pipe(
      filter(resp => !!resp),
      takeUntil(this.unsubscribe),
    ).subscribe((resp) => {
      this.level = Math.ceil(resp?.levelNumber / 5);
    });
    if (!isPlatformServer(this.platformId)) {

      const siteId = 3925400;
      const hotjarVersion = 6;
      // if (this.licence === 'bison_india' || this.locale.toLowerCase() === 'no' || this.locale.toLowerCase() === 'en-ca') {
      //   Hotjar.init(siteId, hotjarVersion);
      // }
      this.router.events.pipe(
        filter((event) => event instanceof NavigationStart),
        //filter((event: RouterEvent) => event instanceof NavigationStart),
      ).subscribe(event => {
        // if (this.detectDeviceService.isDesktop()) {
        //   if (!event.url.startsWith('/' + desktopTrx)) {
        //     this.router.navigateByUrl(`/${desktopTrx}${event.url.length > 1 ? event.url.replace('/?', '?') : ''}`)
        //   }
        // }
        // else {
        if ((event as RouterEvent).url.startsWith('/' + desktopTrx)) {
          this.router.navigateByUrl((event as RouterEvent).url.substring(desktopTrx.length + 1))
        }
        // }
      }
      )
    }

    this.userInfoLoginService.requestCheckUserInfo().pipe(takeUntil(this.unsubscribe)).subscribe((resp) => { });

    this.minimizeSiteService.isRegularPage().pipe(takeUntil(this.unsubscribe), distinctUntilChanged(), delay(10)).subscribe(resp => {
      this.isRegularPage = resp;
    });

    this.minimizeSiteService.isAllNotMinimized().pipe(takeUntil(this.unsubscribe), distinctUntilChanged(), delay(10)).subscribe((res) => {
      this.isFullPage = res;
    });

    this.intialDataService.fetchData().pipe(take(1)).subscribe(resp => {
      this.isMaintenance = resp.maintenance.enabled;

      if (!isPlatformServer(this.platformId)) {
        resp.pageScripts.forEach(script => {
          if (!!script.script) {
            let scriptElement = this._renderer2.createElement('script');
            scriptElement.type = `text/javascript`;
            scriptElement.src = script.script;
            this._renderer2.appendChild(this._document.body, scriptElement);
          }
        })
      }
    })
    let title = '';
    if (!!this.licenceService.getKey("title-substitute")) {
      const title = this.licenceService.getKey("title-substitute");
      this.setSEOTags(title);
    }
    else {
      // this.titleService.setTitle($localize`:@@title-front-page-text:Lemon Casino - the best Online Slots and Live Casino Games`);
      this.setSEOTags()
    }
    // this.meta.removeTag('description');
    // this.meta.addTag({ name: 'description', content: $localize`:@@meta-front-page:Join Lemon Casino Best Online Casino. Online Slots or maybe Live Casino Games? You decide. A wide range of games and benefits for new players and many loyalty bonuses such as Cashback, Lemon Lottery, or Treasure Box!` })
    this.cookiesService.set('firebase-country-override', this.locale);
    // this.pageMappingService.createStaticPageMapping();

    this.route.queryParams.pipe(takeUntil(this.unsubscribe), take(2)).subscribe((params) => {
      if (!!params && params['action']) {
        handleMessages(this, this.matDialog, params['value'], this.gameStateService, this.router, this.loginStatus, this.translationConfig);
        this.router.navigate([this.router.url.split('?')[0]])
      }
    })
    this.openDrawerService.getStateInitial().subscribe(
    )
    this.openDrawerService.getState().subscribe(

      resp => {
      }
    )
  }
  refreshBonusList(): void {
    this.bonusQueueService.getBonusList(true)
      .pipe(take(1), takeUntil(this.unsubscribe))
      .subscribe();
  }

  ngAfterViewInit(): void {
    if (!!this.sidenav) {
      this.sidenav.openedStart.pipe(takeUntil(this.unsubscribe)).subscribe(() =>
        this.isOpened = true
      )
      this.sidenav.closedStart.pipe(takeUntil(this.unsubscribe)).subscribe(() =>
        this.isOpened = false
      )
    }
    if (!isPlatformServer(this.platformId)) {
      this.loginStatus.getIfUserLogged().pipe(take(1)).subscribe((userInfo) => {
        this.depositService.getDepositDetail(userInfo).pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
          for (const deposit of resp) {
            console.log('deposit', deposit)
            const isHasDeposit = userInfo.fullInfo.has_deposit;
            gtag('event', isHasDeposit ? 'subsequent_deposit' : 'first_deposit', {
              'user_id': userInfo.username, // user ID value is used for cross device tracking
              'email_hashed': hashCode(userInfo.username), // sha256 hash
              'email': userInfo.username,
              'value': deposit.deposit_value,
              'currency': userInfo.currency,
            })
          }
        });
      })
    }

    this.loginStatus.getLoginStatus().subscribe({
      next: () => this.refreshBonusList(),
      error: (err) => console.error('Error fetching login status:', err)
    });

    this.loginStatus.getLoginStatus().pipe(
      filter(status => status.isLogged === LoggedStatus.logged),
    ).subscribe((userInfo) => {
      // if (this.licence === 'bison_india' || this.locale.toLowerCase() === 'no' || this.locale.toLowerCase() === 'en-ca') {
      //   this.preloadService.updateStore(resp.username);
      //   Hotjar.identify(resp.username, {
      //     email: resp.username,
      //   });
      // }

      if (!isPlatformServer(this.platformId)) {


        this.notificationService.fetchNotifications().pipe(filter(resp => !!resp), takeUntil(this.unsubscribe)).subscribe((resp) => {
          const notificationNames: string[] = ["new-level", "chargeback-resolved", "tournament-prize", "first-deposit-boost-bonus", "campaign-update", "campaign-update-xmas", "vip-status", "tier-change"]
          const notificationsName: string = notificationNames.includes(resp.notifications[0]?.name.toLowerCase()) ? resp.notifications[0]?.name.toLowerCase() : "default-notification"

          !!resp.notifications[0] && this.snackBar.openFromComponent(DynamicNotificationComponent, {
            data: {
              text: resp.notifications[0].text,
              name: notificationsName,
              level: this.level
            },
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['my-snackbar', notificationsName],
            duration: 10000
          });
          this.notificationService.setNotificationSeen();



        });


        this.userCampaignService.getActiveCampaign().pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
        });
      }

      // this.campaignService.getActiveCampaign().pipe(take(1)).subscribe(resp => {
      //   console.log(resp)
      // })

    });

    if (!isPlatformServer(this.platformId)) this.preloadImages();
  }

  setSchemaSets() {
    const schemaData = {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "WebSite",
          "@id": `${this.hostname}`,
          "url": `${this.hostname}`,
          "name": "Lemon Casino",
          "inLanguage": this.locale
        },
        {
          "@type": "Organization",
          "@id": `${this.hostname}/#organization`,
          "name": "Lemon Casino",
          "url": `${this.hostname}`,
          "logo": {
            "@type": "ImageObject",
            "url": `${this.hostname}/assets/logo/desktop-logo-125x32.svg`
          },
          "brand": "Lemon Casino",
          "sameAs": ["https://t.me/lemoncasino"]
        },
        {
          "@type": "WebPage",
          "@id": `${this.hostname}/#webpage`,
          "url": `${this.hostname}`,
          "name": "Lemon Casino - Kasyno Online Oficjalna Strona",
          "description": "Lemon Casino oferuje ponad 5400 gier kasynowych: sloty online, gry z Å¼ywym krupierem, turnieje, gry zrÄ™cznoÅ›ciowe i wiele innych. Zarejestruj siÄ™ juÅ¼ dziÅ› w Lemon Casino, aby otrzymaÄ‡ bonus powitalny!",
          "inLanguage": this.locale,
          "about": {
            "@id": `${this.hostname}/#organization`
          }
        }
      ]
    };
    this.seoSchemaValidator.addSchemaMarkup(schemaData)
  }

  showInstallPromotion() {
    this.isPromotion = true;
  }

  hideInstallPromotion() {
    this.isPromotion = false;
  }

  showInstallPopup() {
    this.showInstall = true;
    this.hideInstallPromotion();
  }

  hideInstallPopup() {
    this.showInstall = false;
  }

  private preloadImages() {
    // this.preloadImagesService.fetchData().pipe(takeUntil(this.unsubscribe)).subscribe((url) => {
    //   if (!!url) {
    //     let img = new Image();
    //     img.src = url;
    //   }

    // })
  }

  topClicked(value: topMenuAction) {
    if (value === topMenuAction.TOP) {
      this.sidenav.open();
    }
    else if (value === topMenuAction.BACK) {
      const returnUrl = (this.navigation.subjectPrevUrl.value.includes(this.translationConfig.getTranslation(signUpTrx)) || this.navigation.subjectPrevUrl.value.includes(this.translationConfig.getTranslation(loginTrx))) ? '/' : this.navigation.subjectPrevUrl.value;
      this.router.navigateByUrl(returnUrl);
    }
  }

  close() {
    this.sidenav.close();
  }
}
