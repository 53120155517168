import { AfterViewInit, Component, Inject, LOCALE_ID, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationStart, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { GameCategory } from 'src/app/apollo/models/base-models';
import { LICENCE } from 'src/app/global.tokens';
import { christmasCalendarTrx, loginTrx } from 'src/app/router-translation.labels';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { MinimizeSiteService } from 'src/app/services/utils/hide-footer.service';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { BasePageComponent } from '../../base-page/base-page.component';

@Component({
  selector: 'app-desktop-front-page',
  templateUrl: './desktop-front-page.component.html',
  styleUrls: ['./desktop-front-page.component.scss']
})
export class DesktopFrontPageComponent extends BasePageComponent implements AfterViewInit {
  isCalendar: boolean;
  isVipPage: boolean = false;
  constructor(
    private router: Router,
    public detectDeviceService: DetectDeviceService,
    public minimizeSiteService: MinimizeSiteService,
    protected licenceService: LicenceService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(LICENCE) public licence: string,
  ) {
    super()
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.checkCalendar(event.url);
        this.isVipPage = event?.url?.includes('vip');
      }
    });
  }

  categories: GameCategory[];
  operators: GameCategory[];
  showFiller = false;
  @ViewChild('drawer') drawer: MatDrawer;
  toggleClosed: boolean = true;
  isOpened: boolean = false;


  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (!!this.drawer) {
      this.drawer.openedStart.pipe(takeUntil(this.unsubscribe)).subscribe(() =>
        this.isOpened = true
      )
      this.drawer.closedStart.pipe(takeUntil(this.unsubscribe)).subscribe(() =>
        this.isOpened = false
      )
    }
  }

  login() {
    this.router.navigate([this.router.url, loginTrx]);
  }

  closeDrawer() {
    this.drawer.close();
  }
  toggleDrawer(closed: boolean) {
    this.toggleClosed = closed;
    this.drawer.toggle();
  }

  checkCalendar(route: string) { // To make christmas page into full width instead of fixed width
    var check = '/' + christmasCalendarTrx;
    if (check == route || '/' + christmasCalendarTrx == route) {
      this.isCalendar = true;
    } else {
      this.isCalendar = false;
    }
  }

}
