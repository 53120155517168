import { AfterViewInit, Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { InitialDataService } from 'src/app/services/page/initial-data.service';
import { BasePageComponent } from '../../base-page/base-page.component';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Router } from '@angular/router';
import { HOST_NAME } from 'src/app/global.tokens';
import { UntypedFormControl } from '@angular/forms';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { LoggedStatus } from 'src/app/services/auth/login/login.models';
import { gameTrx, liveCasinoTrx, signUpTrx } from 'src/app/router-translation.labels';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { Language } from 'src/app/apollo/models/base-models';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { DocumentData, documentData, SectionCard, sectionsData, Status, UserDocumentsResponse } from 'src/app/services/profile/upload-documents.models';
import { UploadDocumentsService } from 'src/app/services/profile/upload-documents.service';
import { PendingWithdrawalService } from 'src/app/services/payment/withdrawal/pending-withdrawal.service';
import { PendingWithdrawalList } from 'src/app/services/payment/withdrawal/withdrawal.models';
import { UserMessagesService } from 'src/app/services/user-messages/user-messages.service';

@Component({
  selector: 'app-desktop-sidenav',
  templateUrl: './desktop-sidenav.component.html',
  styleUrls: ['./desktop-sidenav.component.scss']
})
export class DesktopSidenavComponent extends BasePageComponent implements AfterViewInit {
  @Output() closeNav = new EventEmitter();
  @Input() toggle: boolean;
  childData: any;
  currentUrl: string;
  languagelist = new UntypedFormControl('');
  isLogin: boolean = false;
  public userDocumentData: SectionCard[];
  hasDocumentRequest: boolean;
  pendingList: PendingWithdrawalList;
  unreadMessages: number = 0;

  _isVisible: boolean = false;

  @Input() set isVisible(value: boolean) {
    this._isVisible = value;
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  liveCasinoRoute: string = `/${this.translationConfig.getTranslation(liveCasinoTrx)}`;
  slot: string = `/${this.translationConfig.getTranslation(gameTrx)}`;
  selectedLanguage: Language;
  constructor(
    public initialData: InitialDataService,
    private router: Router,
    private cookie: CookieService,
    private activeRoute: ActivatedRoute,
    protected loginStatus: LoginStatusService,
    protected translationConfig: TranslationConfig,
    private documentService: UploadDocumentsService,
    protected licenceService: LicenceService,
    private pendingListService: PendingWithdrawalService,
    @Inject(HOST_NAME) public hostName,
    @Inject(LOCALE_ID) public locale,
    private userMessagesService: UserMessagesService,
  ) { super(); }

  ngOnInit(): void {
    this.loginStatus.getIfUserLogged().pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
      this.isLogin = true;
      if (this.isLogin) {
        this.documentService.updateUserDocuments();
        this.documentService.getUserDocuments().pipe(takeUntil(this.unsubscribe)).subscribe((userDocumentsResponse: UserDocumentsResponse) => {
          if (userDocumentsResponse) {
            this.userDocumentData = this.documentService.fillSectionCards(sectionsData, userDocumentsResponse, this.documentService.getSectionsCookie());
            this.hasDocumentRequest = !!this.userDocumentData.find((item) => !(item.status === this.documentService.translateStatus(Status.Approved) || item.status === this.documentService.translateStatus(Status.NotRequired)) && !item.is_hide);
          }

        });

        setTimeout(() => { // Side Nav count notifications api calls here
          this.pendingWithdrawalCheck();
        }, 0);
      }
    })
    this.childData = this.initialData.fetchData().pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
      this.childData = resp;
      // console.log(this.childData.topMenuItems)
      this.selectedLanguage = resp.languages.filter(item => {
        return item.name === this.locale
      })[0];
    })
  }

  closeSideNav() {
    this.closeNav.emit('');
  }

  setCookie($event: any) {
    const language = $event.value;
    this.cookie.set('language', language, 2592000, '/', this.hostName);
    window.location.href = '/' + language + '/';
  }

  signup() {
    this.router.navigate([this.router.url, this.translationConfig.getTranslation(signUpTrx)]);
    this.closeSideNav();
  }

  pendingWithdrawalCheck() {
    if (this.isLogin) {
      this.pendingListService.getList().subscribe();
      this.pendingListService.pendingWithdrawalsCount$.pipe(takeUntil(this.unsubscribe)).subscribe({
        next: (resp) => {
          this.pendingList = resp;
        },
        error: (error) => {
          console.log(error)
        }
      });
      this.userMessagesService.unreadMessagesCount.pipe(takeUntil(this.unsubscribe)).subscribe((unreadCount) => {
        this.unreadMessages = unreadCount;
      });
    }
  }

  ngAfterViewInit() {
    // Side Nav count notifications api calls here
    this.pendingWithdrawalCheck();
  }

}
